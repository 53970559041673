@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-image-a {
  background: linear-gradient(0deg, rgba(1, 91, 187, 0.8), rgba(1, 91, 187, 0.8)),
    linear-gradient(180deg, rgba(1, 91, 187, 0) 0%, #015bbb 80%), url(./images/a.jpg?as=jpg),
    linear-gradient(to bottom, #001f3f, #001f3f 75%, #015bbb);
  background-blend-mode: lighten, normal, luminosity;
  background-position: 0 0, 0 0, center -220px;
  background-repeat: repeat, repeat, no-repeat, no-repeat;
  background-size: auto 840px, auto 840px, auto 960px, auto 840px;
}

.bg-image-b {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 41.6%, #ffffff 100%),
    linear-gradient(0deg, #000000, #000000), url(./images/b.jpg?as=jpg),
    linear-gradient(to bottom, #444444, #444444 75%, #ffffff);
  background-blend-mode: normal, lighten, luminosity;
  background-position: 0 0, 0 0, center -120px;
  background-repeat: repeat-x, repeat-x, no-repeat, no-repeat;
  background-size: auto 840px, auto 840px, auto 960px, auto 840px;
}
